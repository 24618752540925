<template>
  <v-dialog
    v-model="active"
    transition="dialog-bottom-transition"
    max-width="700">
    <template #default>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              {{ warehouse.name }}
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <v-dialog
            v-model="subDialog"
            max-width="700">
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on">
                Add
              </v-btn>
            </template>
            <v-card>
              <v-toolbar flat>
                <v-toolbar-title>
                  <span class="capitalize">
                    Add User
                  </span>
                </v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical />
                <v-spacer />
                <div
                  class="d-flex flex-row justify-end align-center"
                  style="gap: 8px;">
                  <v-autocomplete
                    v-model="query.role"
                    :items="roles"
                    label="Role"
                    outlined
                    dense
                    hide-details />
                  <search-box
                    v-model="query.search"
                    @on-search="onSearch()" />
                </div>
              </v-toolbar>
              <v-card-text>
                <v-data-table
                  v-model="selectedUsers"
                  :headers="subHeaders"
                  :items="allUsers"
                  :items-per-page="10"
                  :loading="subLoading"
                  item-key="id"
                  show-select
                  disable-sort
                  fixed-header
                  height="50vh"
                  class="elevation-1" />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  :disabled="subLoading"
                  @click="onCloseSubDialog()">
                  Cancel
                </v-btn>
                <v-btn
                  :loading="subLoading"
                  color="success"
                  text
                  @click="onSubmitSubDialog()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="combinedUsers"
            :items-per-page="10"
            :loading="loading"
            item-key="id"
            disable-sort
            fixed-header
            height="50vh"
            class="elevation-1">
            <template #[`item.email`]="{ item }">
              <span
                :style="`color: ${item.color || '#000000'}`">
                {{ item.email }}
              </span>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-icon
                v-if="item.color === 'green'"
                color="error"
                small
                @click="cancelAddUser(item)">
                mdi-close
              </v-icon>
              <v-icon
                v-else-if="item.color === 'red'"
                color="error"
                small
                @click="cancelRemoveUser(item)">
                mdi-close
              </v-icon>
              <v-icon
                v-else
                small
                @click="removeUser(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            :disabled="loading"
            @click="onClose()">
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            color="success"
            text
            @click="onConfirmSubmit()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import UserProvider from '@/resources/UserProvider'

const UserService = new UserProvider()

export default {
  components: {
    SearchBox
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    warehouse: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      subLoading: false,
      roles: [
        'management',
        'area_manager',
        'marketing',
        'developer',
        'designer',
        'inventory_and_costing',
        'merchandising_planner',
        'merchandiser',
        'creative',
        'graphic',
        'online_admin',
        'office_admin',
        'accounting',
        'accounting_manager',
        'vm',
        'support',
        'warehouse_manager',
        'warehouse',
        'store_manager',
        'acting_assist_store_manager',
        'assist_store_manager',
        'sales_staff',
        'to_be_assigned'
      ],
      headers: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'E-mail',
          value: 'email'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      subHeaders: [
        {
          text: 'ID',
          value: 'userId'
        },
        {
          text: 'E-mail',
          value: 'email'
        },
        {
          text: 'Role',
          value: 'role'
        }
      ],
      users: [],
      allUsers: [],
      selectedUsers: [],
      addingUsers: [],
      removingUsers: [],
      subDialog: false,
      query: {
        page: 1,
        limit: 9999,
        search: '',
        role: null
      }
    }
  },
  computed: {
    active: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    combinedUsers () {
      const adding = this.addingUsers.map((v) => ({ ...v, id: v.userId, color: 'green' }))
      const removing = this.removingUsers.map((v) => ({ ...v, color: 'red' }))
      const removingIds = removing.map((v) => v.id)
      const users = this.users.filter((v) => !removingIds.includes(v.id))

      return [...adding, ...removing, ...users]
    }
  },
  watch: {
    active (val) {
      if (val) {
        this.getUserByWarehouse()
      }
    },
    subDialog (val) {
      if (val) {
        this.selectedUsers = [...this.addingUsers]
        this.getUsers()
      }
    }
  },
  methods: {
    onSearch () {
      this.getUsers()
    },
    async getUserByWarehouse () {
      try {
        this.loading = true
        this.users = []
        this.selectedUsers = []
        this.addingUsers = []
        this.removingUsers = []

        const { data } = await UserService.getUsersByBranch(this.warehouse.id)

        this.users = data.map((v) => ({ ...v.user, docId: v.id }))
      } catch (error) {
        console.error('getUserByWarehouse', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getUsers () {
      try {
        this.subLoading = true

        const userIds = this.users.map((v) => v.id)
        const { data } = await UserService.getUsers(this.query)

        this.allUsers = data.results.filter((v) => !userIds.includes(v.userId))
      } catch (error) {
        console.error('getUsers', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.subLoading = false
      }
    },
    onConfirmSubmit () {
      this.$store.dispatch('Components/setModal', {
        value: true,
        title: 'Update user branch',
        message: 'Do you want to update the user branch?',
        confirmText: 'Confirm',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.onSubmit()
      })
    },
    async onSubmit () {
      try {
        this.loading = true

        await Promise.all(this.addingUsers.map((user) => {
          const payload = {
            warehouse: this.warehouse,
            sellsukiWarehouse: this.warehouse?.sellsukiWarehouse || null,
            user: {
              id: user.userId,
              email: user.email
            }
          }

          if (!payload.sellsukiWarehouse) {
            delete payload.sellsukiWarehouse
          }

          return UserService.createUserBranch(payload)
        }))

        await Promise.all(this.removingUsers.map((user) => UserService.deleteUserBranch(user.docId)))

        this.onClose()

        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: 'Update users success',
          type: 'success'
        })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    removeUser (item) {
      const index = this.users.findIndex((v) => v.id === item?.id || v.id === item?.userId)

      if (index === -1) {
        return
      }

      this.removingUsers.push({
        ...item,
        docId: this.users[index].docId
      })
    },
    cancelAddUser (item) {
      const index = this.addingUsers.findIndex((v) => v.userId === item?.id || v.userId === item?.userId)

      if (index === -1) {
        return
      }

      this.addingUsers.splice(index, 1)
    },
    cancelRemoveUser (item) {
      const index = this.removingUsers.findIndex((v) => v.id === item?.id || v.id === item?.userId)

      if (index === -1) {
        return
      }

      this.removingUsers.splice(index, 1)
    },
    onSubmitSubDialog () {
      this.addingUsers = [...this.selectedUsers]

      this.onCloseSubDialog()
    },
    onCloseSubDialog () {
      this.subDialog = false
    },
    onClose () {
      this.active = false
    }
  }
}
</script>

<style scoped>

</style>
