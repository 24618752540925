var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"700"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.warehouse.name)+" ")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Add ")])]}}]),model:{value:(_vm.subDialog),callback:function ($$v) {_vm.subDialog=$$v},expression:"subDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"capitalize"},[_vm._v(" Add User ")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('div',{staticClass:"d-flex flex-row justify-end align-center",staticStyle:{"gap":"8px"}},[_c('v-autocomplete',{attrs:{"items":_vm.roles,"label":"Role","outlined":"","dense":"","hide-details":""},model:{value:(_vm.query.role),callback:function ($$v) {_vm.$set(_vm.query, "role", $$v)},expression:"query.role"}}),_c('search-box',{on:{"on-search":function($event){return _vm.onSearch()}},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.subHeaders,"items":_vm.allUsers,"items-per-page":10,"loading":_vm.subLoading,"item-key":"id","show-select":"","disable-sort":"","fixed-header":"","height":"50vh"},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.subLoading},on:{"click":function($event){return _vm.onCloseSubDialog()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"loading":_vm.subLoading,"color":"success","text":""},on:{"click":function($event){return _vm.onSubmitSubDialog()}}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.combinedUsers,"items-per-page":10,"loading":_vm.loading,"item-key":"id","disable-sort":"","fixed-header":"","height":"50vh"},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(("color: " + (item.color || '#000000')))},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.color === 'green')?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.cancelAddUser(item)}}},[_vm._v(" mdi-close ")]):(item.color === 'red')?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.cancelRemoveUser(item)}}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.removeUser(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.onClose()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"success","text":""},on:{"click":function($event){return _vm.onConfirmSubmit()}}},[_vm._v(" Save ")])],1)],1)]},proxy:true}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})}
var staticRenderFns = []

export { render, staticRenderFns }